import React from "react";

export default function InputTextarea({
  name,
  id,
  type = "text",
  label = false,
  placeholder = "",
  defaultValue = "",
  register,
  errors = false,
  errorMessage = "",
  value = "",
  options = [],
  isRequired,
  ...props
}) {
  return (
    <div className="input-container">
      {label && (
        <label className={`label label--filled`} htmlFor={id}>
          {label}
          {isRequired && <span className={`required-mark`}>*</span>}
        </label>
      )}

      <select
        className={`input input--select ${errors && "invalid"} ${
          errors.length
        }`}
        name={name}
        id={id}
        type={type}
        placeholder={placeholder}
        defaultValue={defaultValue}
        ref={register}
        {...props}
      >
        {options.map((el) => (
          <option value={el}>{el}</option>
        ))}
      </select>

      {errorMessage && errors && (
        <div className="input-error">
          <span>{errorMessage}</span>
        </div>
      )}
    </div>
  );
}
