import * as yup from "yup";

export const schema = {
  name: {
    name: "name",
    id: "contact-name",
    type: "text",
    component: "InputText",
    label: "Name*",
    placeholder: "Enter Name",
    defaultValue: "",
    errorMessage: "",
    validation: yup.string().required(),
  },
  phone: {
    name: "phone",
    id: "contact-phone",
    type: "text",
    component: "InputText",
    label: "Phone Number*",
    placeholder: "Enter Phone Number",
    defaultValue: "",
    errorMessage: "",
    validation: yup.string().required(),
  },
  emailFrom: {
    name: "emailFrom",
    id: "contact-emailFrom",
    type: "email",
    label: "Email*",
    placeholder: "Enter Email",
    defaultValue: "",
    errorMessage: "",
    validation: yup.string().email().required(),
  },
  address: {
    name: "address",
    id: "contact-address",
    type: "text",
    component: "InputText",
    label: "Address*",
    placeholder: "Enter Address",
    defaultValue: "",
    errorMessage: "",
    validation: yup.string().required(),
  },
  customerType: {
    name: "customerType",
    id: "customer-type",
    type: "select",
    component: "InputOptions",
    label: "Customer Type*",
    placeholder: "",
    defaultValue: "",
    errorMessage: "",
    options: ["Partner Employee", "Solar Customer Referral", "Other"],
    validation: yup.string().required(),
  },
  companyName: {
    name: "companyName",
    id: "company-name",
    type: "text",
    component: "InputText",
    label: "Name of Solar company*",
    placeholder: "Enter Name",
    defaultValue: "",
    errorMessage: "",
    validation: yup.string().required(),
  },
};

const schemaShape = Object.keys(schema).reduce((result, key) => {
  result[key] = schema[key].validation;
  return result;
}, {});

export const resolver = yup.object().shape(schemaShape);

export default {
  schema,
  resolver,
};
