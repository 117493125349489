import Block from "components/Block";
import Button from "components/Button";
import CardBenefit from "components/CardBenefit";
import ContentGrid from "components/ContentGrid";
import GridCards from "components/GridCards";
import HeaderSkew from "components/HeaderSkew";
import Heading from "components/Heading";
import JumbotronCard from "components/JumbotronCard";
import Main from "components/layout/Main";
import MediaContainer from "components/MediaContainer";
import Paragraph from "components/Paragraph";
import Picture from "components/Picture";
import PointerParent from "components/PointerParent";
import React from "react";
import WlLogo from "assets/images/wol/warrior-logo.svg";
import WoL2 from "assets/images/wol/wolchapter3.png";
import WoL2Webp from "assets/images/wol/wolchapter3.webp";
import WolRefForm from "components/forms/WolRef";
import backgroundCover from "assets/images/header_skew/wolref.jpg";

import ScrollToTop from "components/ScrollToTop";

export default function WarriorsOfLightRefPage() {
  const title = `
    <span style="text-align: left; display: inline-block; margin: 0 auto;">
    <span style="font-weight: 100; text-aling: left;">We love supporting our partners</span><br/>
    <strong>- the Warriors of Light -</strong><br/>
    <span style="font-weight: 100;">as they push the clean energy revolution forward</span>
    </span>
  `;

  const points = ["p1", "p4"];

  const impacts = [
    {
      title: "Save More",
      text:
        "Refinance your home with the Go Green Refi and pay off your solar loan while you’re at it.",
    },
    {
      title: "Special Rates",
      text:
        "We offer a .5% lender credit on your closing costs or towards a lower interest rate.",
    },
    {
      title: "No Lender Fees",
      text: "That’s right, $0. We’ll even pay for the cost of your appraisal.",
    },
    {
      title: "Simple & Fast",
      text:
        "Download our free mobile app to submit an application, upload docs, eSign, and track your loan progress in real-time.",
    },
  ];

  return (
    <Main
      page={"wolRef"}
      pagetitle="Warriors Of Light"
      navStyle="wolRef"
      footerDisclaimer="**Consult with your tax advisor about whether you are entitled to a tax credit and the tax deductibility of your loan."
    >
      <HeaderSkew
        background={backgroundCover}
        title={title}
        url="faq"
        backgroundColor="small-wol"
        appearance="grid-white"
      ></HeaderSkew>

      <PointerParent points={points}>
        <Block layer="1" guides={1} appearance="wol">
          <ContentGrid appearance="centered">
            <div className="navigation-ref__menu-reverse">
              <a
                href="#form"
                className="button button--orange-slim"
                aria-label="Learn more"
              >
                Learn more
              </a>

              <a
                href="https://www.goloanpal.com/borrower/onboarding/GPETERSEN@LOANPAL.COM"
                target="_blank"
                rel="noopener noreferrer"
                className="button button--orange-slim"
                aria-label="Go to What we do"
              >
                Apply now
              </a>
            </div>
            <div className="CenterPicture">
              <img src={WlLogo} alt="Warriors of Light" />
            </div>
          </ContentGrid>
        </Block>

        <Block layer="1" guides={1} appearance="regular-bottomless">
          <ContentGrid appearance="main">
            <>
              <Heading type="h2" appearance="h1--dark" id={points[0]}>
                Introducing our Partner Employee Benefit Program
              </Heading>

              <Paragraph appearance="dark">
                As a partner employee or a direct referral from one of our
                trusted solar partners, you qualify for our Partner Employee
                Benefit Program. This program, only available to select Loanpal
                partners, offers you access to our Go Green Refi!
                <br />
                <br />
                Our Go Green Refi was specifically designed to help you save,
                big time.​
              </Paragraph>

              <a
                href="#form"
                className="button button--slim"
                aria-label="Learn more"
              >
                Learn more
              </a>
            </>
            <MediaContainer appearance="offset-1">
              <Picture
                src={WoL2}
                webp={WoL2Webp}
                width="625"
                height="410"
                alt="We are the official sponsor of GivePower Foundation."
              />
            </MediaContainer>
          </ContentGrid>
        </Block>

        <Block layer="1" guides={1} appearance="gray-skew-reverse">
          <ContentGrid appearance="centered">
            <>
              <GridCards modifier={["4-small", "fullwidth"]}>
                {impacts.map((impact) => (
                  <CardBenefit
                    title={impact.title}
                    description={impact.text}
                    modifier={["orange", "hasDescription"]}
                    key={impact.title}
                  />
                ))}
              </GridCards>
            </>
          </ContentGrid>
        </Block>

        <Block layer="1" guides={1} appearance="none">
          <ContentGrid appearance="centered-space">
            <>
              <Heading type="h2" appearance="h1--dark" id={points[1]}>
                Why consolidate your solar loan into your mortgage?
              </Heading>

              <ul
                className="paragraph paragraph--dark list"
                style={{
                  maxWidth: "none",
                }}
              >
                <li>
                  Keep your tax credit which could put thousands in your
                  pocket**
                </li>
                <li>
                  Qualify for a lower rate — with mortgage rates near all-time
                  lows, see if you can lower your rate and save more
                </li>
                <li>
                  Tax deductibility – adding your solar loan to your mortgage
                  may make it tax deductible**
                </li>
              </ul>
            </>
          </ContentGrid>
        </Block>

        <Block layer="1" name="Direct Pay" guides={1} appearance="wol-orange">
          <ContentGrid appearance="centered">
            <div className="wol-orange-container">
              <Heading type="h2" appearance="h3--white">
                Submit an application today to see if you can lower your rates
                and save hundreds each month!
              </Heading>

              <a
                href="https://www.goloanpal.com/borrower/onboarding/GPETERSEN@LOANPAL.COM"
                target="_blank"
                rel="noopener noreferrer"
                className="button button--white-small"
                aria-label="Apply now"
              >
                Apply now
              </a>
            </div>
          </ContentGrid>
        </Block>

        <div className="scrollTop" id="form">
          <Block layer="1" guides={1} appearance="gray-skew-reverse">
            <ContentGrid appearance="form">
              <JumbotronCard type="form-slim">
                <WolRefForm emailTo="wol@loanpal.com" />
              </JumbotronCard>
              <ScrollToTop modifier={["slim"]} />
            </ContentGrid>
          </Block>
        </div>
      </PointerParent>
    </Main>
  );
}
